/** @jsx jsx */
import { jsx } from 'theme-ui'
import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'
import { Box, Flex } from 'rebass'
import { uniqueId } from 'lodash'

import { Container, Layout, Section } from '@giraldomac/gatsby-theme-mmdbase/src/components/layout'
import { SEO } from '@giraldomac/gatsby-theme-mmdbase/src/components/elements'
import { PubHero } from '../../../components/elements'


const PayBill = ({ data }) => {

    const page = data.prismic.pay_bill

    return (
        <Layout>
            <SEO meta_title={page.meta_title} meta_description={page.meta_description} />
            <PubHero title={RichText.asText(page.title)} />
            <Section >
                <Container>
                    <Flex flexWrap="wrap" justifyContent="center">
                        <Box width={['full', 4 / 5, 3 / 4]} sx={{
                            backgroundColor: 'wrapperbox',
                            padding: [4, 5],
                        }}>
                            {page.content && RichText.render(page.content)}
                            {page.body.map(({ primary }) => (
                                <Box key={`${uniqueId('block_id_')}`}>          {RichText.render(primary.text)}
                                    <hr />
                                </Box>

                            ))}
                        </Box>
                    </Flex>
                </Container>
            </Section>
        </Layout>
    )
}

export default PayBill

export const query = graphql`
query PayBillQuery {
  prismic {
    pay_bill(lang: "en-us", uid: "pay-bill") {
      title
      content
      meta_title
      meta_description
      _linkType
      body {
        ... on PRISMIC_Pay_billBodyText {
          type
          label
          primary {
            text
          }
        }
      }
    }
  }
}
`